<template>
    <div>
        <section class='section1'>
            <div class='wrap'>
                <div class='write_table zoom'>
                    <div class='zoom_table'>
                        <div class='head'>
                            <h3>문의하기</h3>
                            <!-- <div class='type'>
                                <span>문의 유형</span>
                                <label for='type1'>장애신고</label>
                                <input type="checkbox" id='type1' v-model="error">

                                <label for='type2'>Win-Win Pro</label>
                                <input type="checkbox" id='type2' v-model="pro">

                                <label for='type3'>Win-Win Pos</label>
                                <input type="checkbox" id='type3' v-model="pos">

                                <label for='type4'>기타</label>
                                <input type="checkbox" id='type4' v-model="etc">
                            </div> -->
                        </div>
                        <div class='consult_board'>
                            <ul>
                                <li>
                                    <div class='th'>신청자</div>
                                    <div class='tb'><input type='text' placeholder="신청자를 입력해주세요"
                                            v-model="InsertData.writer" /></div>
                                </li>
                                <li>
                                    <div class='th'>연락처</div>
                                    <div class='tb'><input type='text' placeholder="연락처를 입력해주세요"
                                            v-model="InsertData.contact" /></div>
                                </li>
                                <li>
                                    <div class='th'>제목</div>
                                    <div class='tb'><input type='text' placeholder="제목을 입력해주세요"
                                            v-model="InsertData.tit" /></div>
                                </li>

                                <li>
                                    <textarea placeholder="문의하실 내용을 입력해주세요" v-model='InsertData.Desc' />
                                    </li>
                            </ul>
                        </div>
                        <div class='foot'>
                            <p>
                                <input type='password' placeholder="패스워드" v-model="InsertData.password"/>
                                <label for='public'>비밀글 등록 여부  </label>
                                <input type="checkbox" v-model="private" id='public'>
                            </p>
                        </div>
                        <div class='btn' @click='PostData'>등록</div>
                    </div>
                </div>
                </div>
            </section>
    </div>
</template>

<script>
export default {
        data(){
        return{
                mode:'load',
                error:false,
                pro:false,
                pos:false,
                etc:false,
                private:false,

            InsertData:{
                mode:"insert",
                contact:"",
                tit:"",
                writer:"",
                Desc:"",
                private:false,
                password:"",
                date:this.$moment().format('YYYY-MM-DD')
            }
        }
    },
        methods: { 
        PostData(){
            this.mode = 'insert';

            this.private == true?this.InsertData.private = true:this.InsertData.private = false;
            // console.log(this.InsertData);

            if(this.InsertData.tit == ""){
                alert('제목을 입력해주세요');
                this.mode = 'load'

            }
            else if(this.InsertData.Desc == ""){
                alert('본문을 입력해주세요');
                this.mode = 'load'

            }else if(this.InsertData.password ==""){
                alert("패스워드를 입력하세요(글의 수정과 삭제 인증에 사용됩니다)")
            }
            else if(this.private == true){
                if(this.InsertData.password == ""){
                    alert('패스워드를 입력하세요')
                    this.mode = 'load'
                }
                else{
                    const BaseData = `admin/wsapi/qna/new`
                    // console.log(this.InsertData);
                    this.$Axios.post(BaseData,this.InsertData)
                    .then((result)=>{
                        this.mode = 'load'
                        if(result.data.query == 'ok'){
                            alert('접수되었습니다.')
                            this.$router.go(-1)
                        }
                    })
                }
            }else{
                    const BaseData = `admin/wsapi/qna/new`
                    // console.log(this.InsertData);
                    this.$Axios.post(BaseData,this.InsertData)
                    .then((result)=>{
                        this.mode = 'load'
                        if(result.data.query == 'ok'){
                            alert('접수되었습니다.')
                            this.$router.go(-1)
                        }
                    })
            }
        }
    },
}
</script>

<style lang="scss">
@import './table.scss';
</style>